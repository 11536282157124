import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/auth/pagos" />
    },
    {
        path: '/auth',
        component: AuthLayout,
        routes: [
            {
                path: '/auth/pagos',
                exact: true,
                component: lazy(() => import('views/Login'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        path: '/errors',
        component: ErrorLayout,
        routes: [
            {
                path: '/errors/error-401',
                exact: true,
                component: lazy(() => import('views/Error401'))
            },
            {
                path: '/errors/error-404',
                exact: true,
                component: lazy(() => import('views/Error404'))
            },
            {
                path: '/errors/error-500',
                exact: true,
                component: lazy(() => import('views/Error500'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
];

export default routes;
