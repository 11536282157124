import { DataService } from 'providers/dataService'

export class PaymentProvider {

    constructor() {
        this.dataService = new DataService();

    }
    /**---------------------------------Find order ----------------------------------- */
    findAllPaymentsByPolCodigoOrder = polCodigo => this.queryOrder(1, {
        'polCodigo': polCodigo,
    }, ['pagFechaVencimiento', 'ASC'], '0101000');
    /**---------------------------------Buscar todos los pagos por codigo de la poliza */
    findAllPaymentsByPolCodigo = polCodigo => this.query(1, {
        'polCodigo': polCodigo,
        'pagFechaPago': null
    }, '0101000');

    /**---------------------------------Pagos Pendientes*/
    findPendingPaymentsByPolCodigo = polCodigo => this.queryOrder(1, {
        'detNemonico': 'PAG1',
        'polCodigo': polCodigo,

    }, ['pagFechaVencimiento', 'ASC'], '0101000');
    /** -----------------------------------------Actualizar pagos con trama generica */
    updatePayments = entities => this.action(2, entities, '0101100');

    /**------------------------------------------------------------------------------- */
    query = (typeQuery, parameters, transaction) => {
        const body = {
            'tipoConsulta': typeQuery,
            'entidad': 'Pago',
            'parametros': parameters
        }
        //polCodigo

        return this.dataService.post('/consulta', body, transaction).then(response => {
            return response.bodyOut.data;
        }
        );

    }

    action = (typeAction, entities, transaction) => {
        const body = {
            'tipoAccion': typeAction,
            'entidad': 'Pago',
            'entidades': entities
        }
        //polCodigo
        return this.dataService.post('/accion', body, transaction);

    }


    /** --------------------------------------------------------Ordenar--------------------------- */
    queryOrder = (typeQuery, parameters, order, transaction) => {
        const body = {
            'tipoConsulta': typeQuery,
            'entidad': 'Pago',
            'parametros': parameters,
            'orden': order
        }
        //polCodigo

        return this.dataService.post('/consulta', body, transaction).then(response => {
            return response.bodyOut.data;

        }
        );


    }


    findDetNemonico = (dettNemonico) => {
        const body = {
            'tipoConsulta': 1,
            'entidad': 'DetalleCatalogo',
            'parametros': {
                'detNemonico': dettNemonico,
                'detEstado': 1,
            }
        }
        return this.dataService.post('/consulta', body, '0101000');
    }


    /*findPepEstado = ( pepEstado ) => {        
        const body = {
            'tipoConsulta': 1,
            'entidad': 'Persona',
            'parametros': {
                'perEstado': pepEstado,
                'detEstado': 1,            
            }
        }    
        return this.dataService.post('/consulta', body, '0101000');        
    }*/

    /*findObservacion = ( detNombre ) => {        
        const body = {
            'tipoConsulta': 1,
            'entidad': 'DetalleCatalogo',
            'parametros': {
                'detNombre': detNombre,
                'detEstado': 1,            
            }
        }    
        return this.dataService.post('/consulta', body, '0101000');        
    }*/

    personaPoliza(perCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'PersonaPoliza',
            parametros: {
                perCodigo: perCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    detaaleCatalogo(detNemonico) {
        let body = {
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                detNemonico: detNemonico
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }
}